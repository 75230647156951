import { Component, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import * as $ from "jquery";
import { CallParameter } from "src/app/core/classes/system/call-parameter";
import { ProductType } from "src/app/core/enum/product-type.enum";
import { ApiService, CallApiProdParameter } from "src/app/core/services/api.service";
import { AppGeneralService } from "src/app/core/services/app-general.service";
import { SecurityService } from "src/app/core/services/security.service";

import {
  ScriptLoaderEnum,
  ScriptService,
} from "src/app/core/services/script.service";
import { IndirectPaymentRedirectDialogComponent } from "./indirect-payment-redirect-dialog/indirect-payment-redirect-dialog.component";
import { SuccessfullPaymentDialogComponent } from "./successfull-payment-dialog/successfull-payment-dialog.component";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
  providers: [ScriptService],
})
export class PaymentComponent implements OnInit {
  @Input() ProductId: number;
  @Input() User;
  @Input() Product;
  @Input() Type: ProductType;
  @Input() TransferPayment: any;
  TypeEnum = ProductType;
  isCollapsed1: boolean = true;
  faChevron = faChevronDown;
  paymentId ="";
  dialogSatiSpay : MatDialogRef<any>;

  viewModel: { Satispay: { open: () => void } | null } = {
    Satispay: null
  };

  constructor(
    private activeRoute: ActivatedRoute,
    private ApiService: ApiService,
    private appGen: AppGeneralService,
    private security: SecurityService,
    public dialog: MatDialog,
    private router: Router,
    private script: ScriptService
  ) {

  }

  ngOnInit() {

  }

  CreatePaymentSatispay(){

    if(this.viewModel.Satispay){
      this.viewModel.Satispay.open();
      return;
    }

   let param : CallParameter = new CallParameter("POST",{
      Url : window.location.origin+"/conferma-pagamento/"+"SatiSpay/"+this.ProductId,
      IDPolicy : this.Product.CartPolicy.ID,
    })
    this.ApiService.callApiProduction(CallApiProdParameter.create('/api/SatiSpay/CreatePayment',param, "POST").setSpinner(true)).subscribe(res => {

      this.paymentId = res.ResultData.toString();
      this.script
      .load(ScriptLoaderEnum.Satispay)
      .then((data) => this.configureSatispay(this.paymentId,this.CompleteSatiSpay))
      .catch((error) => console.log(error));

    });
  }

  configureSatispay(paymentId : string, funct : any){


    const SatispayWebButton = (<any>window).SatispayWebButton;

    if (!SatispayWebButton) {
      console.error("Satispay web button not initialized!");
      return;
    }

    this.viewModel.Satispay = SatispayWebButton.configure({
      paymentId,
      completed: function() {
        window.location.href = location.origin + "/conferma-pagamento/SatiSpay/"+ paymentId
      }
    });
     this.viewModel.Satispay.open()


  }
  
  MyProtectionPayment(){
    var dialog = this.appGen.showDialog(null, 
      `MyInsurer Protection è il nuovo sistema brevettato che ti permette di pagare l'assicurazione, da te scelta, mese per mese finché ne hai bisogno.
      <br> La polizza si rinnova automaticamente ogni 30 giorni e puoi disdirla quando vuoi!
      <br> Clicca sul tasto PAGA per andare alla sezione pagamenti:
      `,
       "Paga mensilmente!", 
       [
         {name: "Annulla", action: () => {dialog.close();}},
        {name: "Paga", action: () => {this.PaymentRequest(1, true); dialog.close();}}
       ]
       , false);
  }

  CreditCardPayment() {
    if(this.Product?.IsSplitPayment != null && this.Product?.IsSplitPayment != undefined && this.Product?.IsSplitPayment == true){
      var dialog = this.appGen.showDialog(null, 
        "My Insurer ti da' la possibilità di pagare in comode rate la tua polizza! <br> Seleziona la modalità di pagamento che preferisci:",
        "Paga a rate!",
        [
          {name: "unico", action: () => {this.PaymentRequest(1); dialog.close();}}, 
          {name: "semestrale", action: () => {this.beforePayment(6, dialog)}},
          {name: "quadrimestrale", action: () => {this.beforePayment(4, dialog)}},
          {name: "trimestrale", action: () => {this.beforePayment(3, dialog)}},
        ], false);
      return;
    }

    this.PaymentRequest(1);
  }

  beforePayment(months, dialogCaller) {
    var priceRaises = [{month: 6, percentage: 3},
            {month: 4, percentage: 4},
            {month: 3, percentage: 5}];

    dialogCaller.close();
    dialogCaller.afterClosed().subscribe(() => {
      var dialog = this.appGen.showDialog(null,
        `Confermi di voler pagare la tua polizza in ${12 / months} rate? <br> <b>Attenzione:</b> ` + 
        `il pagamento in rate comporta un costo aggiuntivo percentuale del ${priceRaises.find(f => f.month == months).percentage}%.`,
        "Conferma pagamento",
        [
          {name: "Si", action: () => {this.PaymentRequest(months); dialog.close();}},
          {name: "No", action: () => {dialog.close();}}
        ]);
    });
  }

  PaymentRequest(months, isMyProtection = false) {

    let url = "/api/Service/NexiServicePayment";
    let callParam: CallParameter = new CallParameter("POST", {
      UserServiceID: this.ProductId,
      IsRecurrent: months > 1 ? true : false,
      Months: months
    });

    if (this.Type == this.TypeEnum.Policy) {
      url = "/api/Policy/NexiPolicyPayment";
      callParam = new CallParameter("POST", {
        UserPolicyId: Number(this.Product.CartPolicy.ID),
        IsRecurrent: months > 1 || isMyProtection ? true : false,
        Months: months
      });
    }

    this.ApiService.callApiProd(url, callParam).subscribe((data) => {
      if (data.InError) {
        this.appGen.showDialog(data.ErrorCode);
      } else {
        this.appGen.showDialog(-5000);
        setTimeout(() => {
          let form = $(data.ResultData["FormRedirect"].Content);

          form.appendTo($('body'));

          if (form.attr('method') === 'get') {
            let action: string | undefined = form.attr('action');

            if (action) window.location.href = action;
          } else {
            form.trigger('submit');
          }
        }, 1000);
      }
    });
  }

  successFullPaymentModalShow(PaymentMode) {
    var mode = "";
    switch (PaymentMode) {
      case "INDIRECT":
      case "TRANSFER":
        mode = "Polizza in fase di verifica";
        break;
      case "CREDIT_CARD":
        mode = "Polizza pagata";
        break;
      default:
        mode = "Polizza in fase di verifica";
        break;
    }

    this.dialog.open(SuccessfullPaymentDialogComponent, {
      width: "500px",
      disableClose: true,
      data: {
        title: mode,
      },
      panelClass: "custom-dialog-container",
    });
  }

  IndirectPayment() {
    if (this.security.LoginError()) {
      return;
    }

    this.appGen.loadingPanel.Show();

    this.ApiService.callApiProd(
      "/api/Policy/IndirectPayment",
      new CallParameter("POST", {
        UserPolicyId: Number(this.Product.CartPolicy.ID),
      })
    ).subscribe((result) => {
      if (result.InError) {
        this.appGen.showDialog(result.ErrorCode);
      } else {
        if (
          result.ResultData["RedirectTo"] != null ||
          result.ResultData["RedirectTo"] != undefined
        ) {
          this.dialog.open(IndirectPaymentRedirectDialogComponent, {
            width: "500px",
            data: result.ResultData,
            disableClose: true,
            panelClass: "custom-dialog-container",
          });
          return;
        } else {
          this.router.navigate([
            "conferma-pagamento/" + "indirect" + "/" + this.ProductId,
          ]);
          this.successFullPaymentModalShow("INDIRECT");
        }
      }
    });
  }

  CompleteSatiSpay(){

    let param = new CallParameter("GET", {
      payment_id: this.paymentId
    })

    this.ApiService.callApiProduction(CallApiProdParameter.create('/api/SatiSpay/GetStatusPayment',param,"GET").setSpinner(true)).subscribe(res => {

      if(res.ResultData.toString() == "ACCEPTED"){


        this.router.navigate(['conferma-pagamento/' + "SatiSpay" +"/"+ this.ProductId]);
        this.dialogSatiSpay =this.appGen.showDialog(null,"Attendi che i nostri operatori convalidino i documenti che hai caricato.","Polizza in fase di verifica",[{ name: 'OK', action: () => { this.dialogSatiSpay.close() } }]);


      }

    });

  }
}
