import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronDown, faChevronUp, faFileDownload, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { ContractsConditionService } from 'src/app/auth/contracts-condition/contracts-condition.service';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ProductType } from 'src/app/core/enum/product-type.enum';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { SecurityService } from 'src/app/core/services/security.service';
import { FileUploadComponent } from 'src/app/shared/file-upload/file-upload.component';
import { ModuleComponent } from 'src/app/shared/module/module.component';
import { isNullOrUndefined } from 'util';
import { Step } from '../../../shared/stepper/stepper.component';
import { IndirectPaymentRedirectDialogComponent } from '../payment/indirect-payment-redirect-dialog/indirect-payment-redirect-dialog.component';
import { SuccessfullPaymentDialogComponent } from '../payment/successfull-payment-dialog/successfull-payment-dialog.component';
import { AdhesionComponent } from './adhesion/adhesion.component';
import { FileDeleteComponent } from './file-delete/file-delete.component';
import { OptionDTO } from 'src/app/core/classes/Policy/compare';
import { OptionInfoDialogComponent } from '../../policies/policy-group/policy-price-boxes/option-info-dialog/option-info-dialog.component';

@Component({
  selector: 'app-buy-policy',
  templateUrl: './buy-policy.component.html',
  styleUrls: ['./buy-policy.component.scss']
})
export class BuyPolicyComponent implements OnInit {


  faFileDownload = faFileDownload;
  faDownload = faFileDownload;
  faUpload = faUpload;
  faTimes = faTimes;
  faChevronDownDocuments = faChevronDown;
  public isCollapsed = true;
  CartPolicyDetail;
  UserPolicyID;
  UserName;
  User;
  Iban = "IT74C0503401437000000000616";
  productType = ProductType;
  transferFileID: number;
  isAifiPolicy: boolean = false;
  form: UntypedFormGroup;
  isHorizontal: boolean = true;
  selectedStep: number;
  isAccordionOpen = false;
  moduleCompileDisabled = "";

  Steps: Array<Step> = [];
  @ViewChild('InfoDocuments') InfoTemplate: TemplateRef<any>;
  @ViewChild('UploadDocuments') UploadTemplate: TemplateRef<any>;
  @ViewChild('Payment') PaymentTemplate: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    public appGeneralService: AppGeneralService,
    private contactsConditionService: ContractsConditionService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private httpClient: HttpClient,
    private securityService: SecurityService,
    private storageService: LocalStorageService
  ) {


  }

  ngOnInit() {

    this.UserPolicyID = Number(this.activatedRoute.snapshot.paramMap.get("id")); // converto il valore dell'url da stringa a numero
    this.getCartPolicyDetail();

    this.UserName = this.storageService.getUserData();

  }

  private LoginError = (): boolean => { if (!this.securityService.IsLogged) { this.securityService.GetLoginErrorModal(this.CartPolicyDetail?.CartPolicy?.HasLiteRegistration); return true; } return false; };

  /**
   * If the document was already uploaded, open the upload file modal.
   * If the document was not already uploaded and the document type is 12, open the adhesion module modal.
   * If the document was not already uploaded and the document type is null and the document code is not null, open the adhesion module modal.
   * @param document - The document to upload.
   */
  handleDocumentsUpload(document) {
    if (!document.WasAlreadyUploaded && document.Type == 12) {
      this.openAdhesionModuleModal()
    } else if (!document.WasAlreadyUploaded && document.Type === null && document.Code !== null) {
      this.openAdhesionModuleModal(document.Code, document.ID)
    } else {
      this.openUploadFileModal(document)
    }
  }

  checkUploadedDocuments(){

    let allDocumentsUploaded = true;
    this.CartPolicyDetail.Documents.forEach(document => {
      if(!document.WasAlreadyUploaded) {
        if(document.Name != "Bonifico"){
        allDocumentsUploaded = false;
        }
      }
    });
    this.CartPolicyDetail.Modules.forEach(module => {
      if(!module.WasAlreadyUploaded) {
        allDocumentsUploaded = false;
      }
    });
    return allDocumentsUploaded;
  }

  checkReadDocuments(){
    let allDocumentsUploaded = true;
    this.CartPolicyDetail.InformativeDocuments.forEach(document => {
      if(!document.WasAlreadyUploaded) {
        allDocumentsUploaded = false;
      }
    });
    return allDocumentsUploaded;
  }

  /**
   * Get the policy details for the policy that was selected in the previous step
   */
  getCartPolicyDetail() {

    this.apiService.callApiProd('/api/Policy/CartPolicyDetail', new CallParameter('POST', { "UserPolicyId": Number(this.UserPolicyID) })).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.CartPolicyDetail = result['ResultData'];
        if(this.CartPolicyDetail['CartPolicy']['Iban']){

          this.Iban = this.CartPolicyDetail['CartPolicy']['Iban'];

        }
        this.transferFileID = result['ResultData']['TransferPolicyFileId'];
        this.User = result['ResultData']['User'];
        this.isAifiPolicy = (this.CartPolicyDetail.CartPolicy.BackOfficeName != null || this.CartPolicyDetail.CartPolicy.BackOfficeName != undefined || this.CartPolicyDetail.CartPolicy != undefined || this.CartPolicyDetail.CartPolicy != null) && this.CartPolicyDetail.CartPolicy.BackOfficeName.indexOf("AIFI") > 0;

        let selectedSubUser = this.CartPolicyDetail.CartPolicy.SelectedSubUser;

        if (selectedSubUser == null) {
          selectedSubUser = -1;
        }

        this.form = this.formBuilder.group({
          insured: new UntypedFormControl(selectedSubUser, Validators.required)
        });

        if(this.Steps.length <= 0){

          if(this.CartPolicyDetail.InformativeDocuments.length > 0) {
            this.Steps.push( Step.create(0, "SCARICA I DOCUMENTI","Prossimo Step", this.InfoTemplate, true, this.checkReadDocuments(), () => {
              const dialogAcceptance = this.contactsConditionService.CheckConfirm(true, true, this.UserPolicyID, this.CartPolicyDetail["PolicyGroupID"]);
              dialogAcceptance.afterClosed().subscribe(result => {
                this.getCartPolicyDetail();
              });
              return true;
            }));
          }

          if(this.CartPolicyDetail.Documents.length > 0 || this.CartPolicyDetail.Modules.length > 0) {
            this.Steps.push( Step.create(1,"COMPILA", "Ultimo Step", this.UploadTemplate, false, this.checkUploadedDocuments(), () => {
              if(this.CartPolicyDetail.Documents.length > 0 || this.CartPolicyDetail.Modules.length > 0){
                return this.checkUploadedDocuments();
              }
              return true;
            }));
          }

          this.Steps.push( Step.create(2,"PAGA", "", this.PaymentTemplate, true));
        }else{
          this.Steps[0].SetCompleted(this.checkReadDocuments());
          this.Steps[1].SetCompleted(this.checkUploadedDocuments());
        }

        this.setHorizontal();

        if (this.LoginError()) {
          return;
        }
      }
    });
  }

  documentCollapse() {
    this.isCollapsed = !this.isCollapsed
    if (this.isCollapsed) {
      this.faChevronDownDocuments = faChevronDown;
      return;
    }
    this.faChevronDownDocuments = faChevronUp;
  }


  /**
   * * If the user has not accepted the conditions of the policy, the user is redirected to the
   * adhesion module.
   * * If the user has accepted the conditions of the policy, the user is asked to confirm the payment.
   * * If the user confirms the payment, the payment is done and the user is redirected to the products
   * purchased page
   * @returns The result of the call to the API.
   */
  IndirectPayment() {

    if (this.NeedsAdhesionModule) {

      this.CBAfterAdhesionModule = this.IndirectPayment;
      this.openAdhesionModuleModal();
    } else {
      const dialogAcceptance = this.contactsConditionService.CheckConfirm(true, false, this.UserPolicyID, this.CartPolicyDetail["PolicyGroupID"]);
      dialogAcceptance.afterClosed().subscribe(result => {
        if (!isNullOrUndefined(result) && result) {
          this.appGeneralService.loadingPanel.Show();

          this.apiService.callApiProd('/api/Policy/IndirectPayment', new CallParameter('POST', { "UserPolicyId": Number(this.UserPolicyID) })).subscribe((result) => {

            if (result.InError) {
              if (result.ErrorCode == 501) {
                this.appGeneralService.showDialog(result.ErrorCode);
              }
            } else {
              if (!isNullOrUndefined(result.ResultData['RedirectTo'])) {
                this.dialog.open(IndirectPaymentRedirectDialogComponent, {
                  width: '500px',
                  data: result.ResultData,
                  disableClose: true,
                  panelClass: 'custom-dialog-container'
                });
                return;
              } else {
                this.router.navigate(['conferma-pagamento/' + "indirect" +"/"+ this.UserPolicyID]);
                this.successFullPaymentModalShow('INDIRECT');
              }
            }
          });
        }
      });


    }
  }

  /**
   * This function is called when the user clicks on the "Pay with Credit Card" button
   * @returns The URL to redirect to.
   */
  CreditCardPayment() {


    if (this.NeedsAdhesionModule) {
      this.CBAfterAdhesionModule = this.CreditCardPayment;
      this.openAdhesionModuleModal();
    } else {
      const dialogAcceptance = this.contactsConditionService.CheckConfirm(true, false, this.UserPolicyID, this.CartPolicyDetail["PolicyGroupID"]);
      dialogAcceptance.afterClosed().subscribe(result => {
        if (!isNullOrUndefined(result) && result) {
          let param = new CallParameter("POST");
          param.CallData = { "UserPolicyId": Number(this.UserPolicyID) }

          this.apiService.callApiProd("/api/Policy/NexiPolicyPayment", param).subscribe(
            data => {
              if (data.InError) {
                this.appGeneralService.showDialog(data.ErrorCode);
              } else {

                this.appGeneralService.showDialog(-5000);

                setTimeout(() => {

                  window.location.href = data.ResultData['UrlToRedirect'];

                }, 3000);

              }
            });
        }
      });

    }
  }

  /**
   * *Show a modal dialog with a title and a message.*
   * @param PaymentMode - The payment mode of the policy.
   */
  successFullPaymentModalShow(PaymentMode) {

    var mode = '';
    switch (PaymentMode) {
      case 'INDIRECT':
      case 'TRANSFER':
        mode = 'Polizza in fase di verifica';
        break;
      case 'CREDIT_CARD':
        mode = 'Polizza pagata';
        break;
      default: mode = 'Polizza in fase di verifica';
        break;
    };

    this.dialog.open(SuccessfullPaymentDialogComponent, {
      width: '500px',
      disableClose: true,
      data: {
        title: mode
      },
      panelClass: 'custom-dialog-container'
    });

  }

  NeedsAdhesionModule: boolean = false;
  CBAfterAdhesionModule: () => void;

  /**
   * This function opens the Adhesion Component
   * @param {DocumentType} [DocumentModuleTypeCode=null] - DocumentType = null, DocumentID: number =
   * null
   * @param {number} [DocumentID=null] - The ID of the document that you want to open.
   */
  openAdhesionModuleModal(DocumentModuleTypeCode: DocumentType = null, DocumentID: number = null) {

    this.appGeneralService.loadingPanel.Show();

    this.apiService.callApiProd('/api/Policy/GetAdhesionModuleData', new CallParameter('POST', { "UserPolicyId": Number(this.UserPolicyID) })).subscribe((result) => {
      if (result.InError) {
          this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        let dialogRef = this.dialog.open(AdhesionComponent, {
          width: '800px',
          height: '900px',
          panelClass: 'responsiveCntr',
          data: {
            PolicyID: parseInt(this.UserPolicyID),
            PolicyData: result.ResultData,
            DocumentModuleTypeCode: DocumentModuleTypeCode,
            DocumentID: DocumentID
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.getCartPolicyDetail();
          }
        });
      }
    });

  }

  externalCaller(){
    return () => {this.TransferPayment()};
  }

  /**
   * * Open a dialog to upload a file
   * @returns Nothing.
   */
  TransferPayment() {

    if (this.NeedsAdhesionModule) {
      this.CBAfterAdhesionModule = this.TransferPayment;
      this.openAdhesionModuleModal();
    } else {
      let dialogRefTransfer = this.dialog.open(FileUploadComponent, {
        width: '500px',
        disableClose: true,
        data: {
          file: {
            ID: this.transferFileID,
            Name: "Bonifico",
            Type: 7,
            Mandatory: false,
            HasOwnDownloadableSource: false,
            WasAlreadyUploaded: false
          },
          Iban: this.Iban,
          id: parseInt(this.UserPolicyID),
          price: parseInt(this.CartPolicyDetail.CartPolicy.Price),
          Code: this.CartPolicyDetail.CartPolicy.Code,
          TransferIndirect: true,
          isAifi: this.isAifiPolicy
        },
        panelClass: 'custom-dialog-container'
      });

      dialogRefTransfer.afterClosed().subscribe(result => {
        if (!isNullOrUndefined(result['redirect']) && result['redirect']) {
          this.IndirectPayment();
        }
      });
    }
  }

  /**
   * Open a dialog to upload a file
   * @param fileData - The file data that was uploaded.
   */
  openUploadFileModal(fileData) {
    if (!fileData.WasAlreadyUploaded) {
      let dialogRef = this.dialog.open(FileUploadComponent, {
        width: '500px',
        data: {
          file: fileData,
          id: parseInt(this.UserPolicyID),
          price: parseInt(this.CartPolicyDetail.Price),
          Code: this.CartPolicyDetail.Code,
          isAifi: this.isAifiPolicy
        },
        disableClose: true,
        panelClass: 'custom-dialog-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (!isNullOrUndefined(result['refresh']) && result['refresh']) {
          this.getCartPolicyDetail();
        }
      });
    }
  }

  /**
   * Open a dialog box that allows the user to delete a policy file from the cart
   * @param UserPolicyID - The ID of the User Policy that contains the file.
   * @param PolicyFileID - The ID of the file that you want to delete.
   * @param [CompiledModuleID=null] - If you are deleting a compiled module, you need to pass the
   * CompiledModuleID.
   */
  openDeleteModal(UserPolicyID, PolicyFileID, CompiledModuleID = null) {

    let dialogRef = this.dialog.open(FileDeleteComponent, {
      width: '500px',
      data: {
        UserPolicyID: UserPolicyID,
        PolicyFileID: PolicyFileID,
        CompiledModuleID: CompiledModuleID
      },
      disableClose: true,
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCartPolicyDetail();
      }
    });

  }

  openDeleteModuleModal(UserPolicyID, ModuleFileID) {

    let dialogRef = this.dialog.open(FileDeleteComponent, {
      width: '500px',
      data: {
        UserPolicyID: UserPolicyID,
        CompiledModuleID: ModuleFileID
      },
      disableClose: true,
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCartPolicyDetail();
      }
    });

  }

  /**
   * Open a modal window with the content of the URL passed as parameter
   * @param {string} Url - string
   * @returns The module component is being returned.
   */
  openModuleModal(Url: string) {

    this.moduleCompileDisabled = "disabled";
    this.appGeneralService.loadingPanel.Show();
    
    this.httpClient.get<any>(Url).subscribe(data => {
      this.appGeneralService.loadingPanel.Hide();
      let dialogRef = this.dialog.open(ModuleComponent, {
        width: '85vw',
        maxHeight: '85vh',
        data: {
          contenuto: data,
          UserPolicyID: this.UserPolicyID
        },
        autoFocus: false,
        disableClose: false,
        panelClass: 'Module-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        this.moduleCompileDisabled = "";
        if (result) {
          this.getCartPolicyDetail();
        }
      });

    })
  }

  /**
   * It saves the policy.
   */
  save() {

    let CallData = {
      SubUserId: this.form.get('insured').value,
      UserPolicyId: this.CartPolicyDetail.CartPolicy.ID
    };

    this.apiService.callApiProd('/api/Policy/UpdatePolicySubUser', new CallParameter('POST', CallData), false).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      }
    });
  }

  openInfoDialog(Description: string, Title: string) {
    this.dialog.open(OptionInfoDialogComponent, {
      panelClass: 'SurveyModalCntr',
      width: '400px',
      maxHeight: '90vh',
      data: {Description, Title},
      autoFocus: false
    });
  }
  toggleAccordion() {
    this.isAccordionOpen = !this.isAccordionOpen;
  }

  @HostListener('window:resize')
  /**
   * The function setHorizontal() is a function that sets the value of the variable isHorizontal to
   * true if the window width is less than 768 pixels.
   * Is called on window resize.
   */
  setHorizontal() {
    this.isHorizontal = window.innerWidth < 768;
  }
}


