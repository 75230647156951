<div class="Slides" [ngStyle]="{'background-image': 'url('+ SingleServiceCarousel.ImageUrls[0] +')'}" (click)="goToDetail(SingleServiceCarousel.ID)">
  <div class="dynamic_cntr">
      <div class="container">
          <div class="text-background"></div>
          <div class="row" style="margin: 15px;">
              <div class="MobileTextCenter">
                  <div class="d-flex" style="margin-left: 3%;">
                      <div style="align-self: center; height: auto;">
                          <div class="m-auto textConstrain" [innerHTML]="SingleServiceCarousel.Name | safe:'html'"></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="MobileDecorationCenter"></div>
</div>