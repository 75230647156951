import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-carousel',
  templateUrl: './service-carousel.component.html',
  styleUrls: ['./service-carousel.component.scss', '../home.component.scss']
})
export class ServiceCarouselComponent implements OnInit {

  constructor() { }

  @Input() ServiceCarousel;

  ngOnInit() {
  }

  @HostListener("window:resize")
  calculateHeight(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1200) {
      return 500;
    }
      else if(windowWidth > 800)
      {
      return 500;
      }
      else if(windowWidth > 600)
      {
      return 400;
      }
      else if(windowWidth > 500)
      {
      return 300;
      }
      else if(windowWidth > 350)
      {
      return 250;
      }
      else if(windowWidth > 0)
      {
      return 200;
      }
  }

}
