<div class="homeCarouselCntr">

    <h2 class="MobileFirstHomeCarouselBox">SERVIZI</h2>

    <div class="first-column">

        <div class="firstHomeCarouselBox">
            <h2>I nostri servizi</h2>
            <img src="../../../../assets/images/freccia.png">
        </div>

    </div>

    <div class="second-column">

        <carousel class="HeaderCarousel" [overflowCellsLimit]="1" [cellWidth]="'100%'" [height]="calculateHeight()"
        [arrows]="ServiceCarousel.Results.length > 1" [autoplay]="ServiceCarousel.Results.length > 1"
        [autoplayInterval]="10000" [arrows]="ServiceCarousel.Results.length > 1" [margin]="0"
        [loop]="ServiceCarousel.Results.length > 1" [transitionTimingFunction]="'ease-in-out'"
        [transitionDuration]="2000" [counter]="false" [pauseOnHover]="true">
            <div class="carousel-cell" *ngFor="let Item of ServiceCarousel.Results">
                <app-single-service-carousel [SingleServiceCarousel]="Item"></app-single-service-carousel>
            </div>
        </carousel>

    </div>

</div>